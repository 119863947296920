import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classnames from 'classnames'
import _ from 'lodash'
import Masonry from 'react-masonry-component'
import { Loader, Modal, Button, Icon } from 'semantic-ui-react'

import SEO from '../components/SEO'
import Layout from '../components/Layout'

export const pageQuery = graphql`
  {
    showcase1: file(relativePath: { eq: "showcases/showcase-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase2: file(relativePath: { eq: "showcases/showcase-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase3: file(relativePath: { eq: "showcases/showcase-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase4: file(relativePath: { eq: "showcases/showcase-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase5: file(relativePath: { eq: "showcases/showcase-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase6: file(relativePath: { eq: "showcases/showcase-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase7: file(relativePath: { eq: "showcases/showcase-7.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase8: file(relativePath: { eq: "showcases/showcase-8.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase9: file(relativePath: { eq: "showcases/showcase-9.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase10: file(relativePath: { eq: "showcases/showcase-10.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase11: file(relativePath: { eq: "showcases/showcase-11.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase12: file(relativePath: { eq: "showcases/showcase-12.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase13: file(relativePath: { eq: "showcases/showcase-13.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase14: file(relativePath: { eq: "showcases/showcase-14.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase15: file(relativePath: { eq: "showcases/showcase-15.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase16: file(relativePath: { eq: "showcases/showcase-16.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase17: file(relativePath: { eq: "showcases/showcase-17.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase18: file(relativePath: { eq: "showcases/showcase-18.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase19: file(relativePath: { eq: "showcases/showcase-19.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase20: file(relativePath: { eq: "showcases/showcase-20.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase21: file(relativePath: { eq: "showcases/showcase-21.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase22: file(relativePath: { eq: "showcases/showcase-22.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase23: file(relativePath: { eq: "showcases/showcase-23.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase24: file(relativePath: { eq: "showcases/showcase-24.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase25: file(relativePath: { eq: "showcases/showcase-25.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase26: file(relativePath: { eq: "showcases/showcase-26.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase27: file(relativePath: { eq: "showcases/showcase-27.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase28: file(relativePath: { eq: "showcases/showcase-28.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase29: file(relativePath: { eq: "showcases/showcase-29.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase30: file(relativePath: { eq: "showcases/showcase-30.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase31: file(relativePath: { eq: "showcases/showcase-31.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase32: file(relativePath: { eq: "showcases/showcase-32.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase33: file(relativePath: { eq: "showcases/showcase-33.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase34: file(relativePath: { eq: "showcases/showcase-34.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase35: file(relativePath: { eq: "showcases/showcase-35.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase36: file(relativePath: { eq: "showcases/showcase-36.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase37: file(relativePath: { eq: "showcases/showcase-37.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase38: file(relativePath: { eq: "showcases/showcase-38.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase39: file(relativePath: { eq: "showcases/showcase-39.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase40: file(relativePath: { eq: "showcases/showcase-40.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase41: file(relativePath: { eq: "showcases/showcase-41.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase42: file(relativePath: { eq: "showcases/showcase-42.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase43: file(relativePath: { eq: "showcases/showcase-43.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase44: file(relativePath: { eq: "showcases/showcase-44.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase45: file(relativePath: { eq: "showcases/showcase-45.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase46: file(relativePath: { eq: "showcases/showcase-46.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase47: file(relativePath: { eq: "showcases/showcase-47.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase48: file(relativePath: { eq: "showcases/showcase-48.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase49: file(relativePath: { eq: "showcases/showcase-49.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase50: file(relativePath: { eq: "showcases/showcase-50.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase51: file(relativePath: { eq: "showcases/showcase-51.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase52: file(relativePath: { eq: "showcases/showcase-52.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase53: file(relativePath: { eq: "showcases/showcase-53.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase54: file(relativePath: { eq: "showcases/showcase-54.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase55: file(relativePath: { eq: "showcases/showcase-55.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase56: file(relativePath: { eq: "showcases/showcase-56.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase57: file(relativePath: { eq: "showcases/showcase-57.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase58: file(relativePath: { eq: "showcases/showcase-58.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase59: file(relativePath: { eq: "showcases/showcase-59.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase60: file(relativePath: { eq: "showcases/showcase-60.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase61: file(relativePath: { eq: "showcases/showcase-61.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase62: file(relativePath: { eq: "showcases/showcase-62.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase63: file(relativePath: { eq: "showcases/showcase-63.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase64: file(relativePath: { eq: "showcases/showcase-64.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase65: file(relativePath: { eq: "showcases/showcase-65.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase66: file(relativePath: { eq: "showcases/showcase-66.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase67: file(relativePath: { eq: "showcases/showcase-67.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase68: file(relativePath: { eq: "showcases/showcase-68.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase69: file(relativePath: { eq: "showcases/showcase-69.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase70: file(relativePath: { eq: "showcases/showcase-70.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase71: file(relativePath: { eq: "showcases/showcase-71.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase72: file(relativePath: { eq: "showcases/showcase-72.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase73: file(relativePath: { eq: "showcases/showcase-73.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase74: file(relativePath: { eq: "showcases/showcase-74.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase75: file(relativePath: { eq: "showcases/showcase-75.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase76: file(relativePath: { eq: "showcases/showcase-76.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase77: file(relativePath: { eq: "showcases/showcase-77.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase78: file(relativePath: { eq: "showcases/showcase-78.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase79: file(relativePath: { eq: "showcases/showcase-79.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase80: file(relativePath: { eq: "showcases/showcase-80.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase81: file(relativePath: { eq: "showcases/showcase-81.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase82: file(relativePath: { eq: "showcases/showcase-82.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase83: file(relativePath: { eq: "showcases/showcase-83.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase84: file(relativePath: { eq: "showcases/showcase-84.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase85: file(relativePath: { eq: "showcases/showcase-85.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase86: file(relativePath: { eq: "showcases/showcase-86.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase87: file(relativePath: { eq: "showcases/showcase-87.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase88: file(relativePath: { eq: "showcases/showcase-88.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
    showcase89: file(relativePath: { eq: "showcases/showcase-89.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`

const GalleryPage = props => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const [visible, setVisible] = useState(false)

  const SHOWCASE_IMAGES = useMemo(() => {
    const storage = []
    for (let i = 0; i < 89; i++) {
      storage.push({
        index: i,
        imgLink: props.data[`showcase${i + 1}`].childImageSharp.gatsbyImageData
      })
    }
    return storage
  }, [props.data])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  })

  const handleShowGallery = useCallback(() => {
    setVisible(true)
  }, [])

  const handleZoomImage = useCallback(e => {
    const { index } = e.currentTarget.dataset
    setActiveIndex(index)
  }, [])

  const handleKeyDown = useCallback(
    e => {
      if (activeIndex === -1) {
        return
      }

      if (e.key === 'ArrowLeft' || e.keyCode === 37) {
        setActiveIndex(Math.max(activeIndex - 1, 0))
      } else if (e.key === 'ArrowRight' || e.keyCode === 39) {
        setActiveIndex(Math.min(activeIndex + 1, SHOWCASE_IMAGES.length - 1))
      }
    },
    [activeIndex, SHOWCASE_IMAGES.length]
  )

  const handleNavigate = useCallback(
    e => {
      const { direction } = e.currentTarget.dataset

      if (activeIndex === -1) {
        return
      }

      if (direction === 'prev') {
        setActiveIndex(Math.max(activeIndex - 1, 0))
      } else if (direction === 'next') {
        setActiveIndex(Math.min(activeIndex + 1, SHOWCASE_IMAGES.length - 1))
      }
    },
    [activeIndex, SHOWCASE_IMAGES.length]
  )

  const handleCloseGallery = useCallback(() => {
    setActiveIndex(-1)
  }, [])

  useEffect(() => {
    if (activeIndex >= 0) {
      const activeThumbnail = document.getElementById(`thumbnail_${activeIndex}`)
      activeThumbnail.scrollIntoView({ behavior: 'smooth', inline: 'center' })
    }
  }, [activeIndex])

  // const handleTouchStart = useCallback(e => {
  //   console.log(e.touches[0])
  // }, [])
  // const handleTouchMove = useCallback(e => {
  //   console.log(e.changedTouches)
  // }, [])
  // const handleTouchEnd = useCallback(e => {
  //   console.log(e.changedTouches)
  // }, [])
  // const handleTouchCancel = useCallback(e => {
  //   console.log(e.changedTouches)
  // }, [])

  return (
    <Layout>
      <SEO
        title="Gallery"
        description="The showcases of Nail salons have been using our pedicure spa chairs to protect their beloved customers. Contego Pedicure Spa Chairs for Cleaner, Safer and Faster sanitation."
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa chairs for sale,Cheap spa pedicure chairs,pedicure spa chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container-fluid py-5">
        <div className="row pb-5">
          <div className="col text-center">
            <h1>CONTÉGO SPA CHAIRS IN ACTION</h1>
            <p>
              Showcases of Nail salons using our pedicure spa massage chairs to protect their
              beloved customers. Contego Pedicure Spa Massage Chairs for Cleaner, Safer and Faster
              sanitation.
            </p>
          </div>
        </div>

        <div className="row">
          <Masonry
            className="w-100 mx-auto"
            options={{ transitionDuration: 3000, fitWidth: true }}
            onImagesLoaded={handleShowGallery}
            // onLayoutComplete={handleShowGallery}
          >
            {visible ? (
              SHOWCASE_IMAGES.map((item, i) => {
                return (
                  <div
                    role="button"
                    tabIndex={i}
                    // onKeyPress={handleKeyDown}
                    data-index={item.index}
                    className="grid-item p-1 clickable"
                    key={`item ${item.index}`}
                    // onClick={handleZoomImage}
                  >
                    <GatsbyImage image={item.imgLink} alt="" />
                  </div>
                )
              })
            ) : (
              <Loader active inline="centered" />
            )}
          </Masonry>
        </div>
      </div>

      <Modal size="fullscreen" open={activeIndex !== -1}>
        <div className="zoom-view-header">
          <h2 className="ml-2 mb-0">GALLERY</h2>

          <Icon name="close" size="large" color="red" onClick={handleCloseGallery} />
        </div>

        <div className="zoom-view-content">
          {activeIndex >= 0 && (
            <img
              src={_.filter(SHOWCASE_IMAGES, { index: Number(activeIndex) })[0].imgLink.src}
              alt={`img_${activeIndex}`}
              className="zoom-view-img"
              // onTouchStart={handleTouchStart}
              // onTouchMove={handleTouchMove}
              // onTouchEnd={handleTouchEnd}
              // onTouchCancel={handleTouchCancel}
            />
          )}

          {activeIndex > 0 && (
            <Icon
              name="chevron left"
              data-direction="prev"
              size="large"
              className="zoom-view-prev-btn"
              onClick={handleNavigate}
            />
          )}

          {activeIndex !== SHOWCASE_IMAGES.length - 1 && (
            <Icon
              name="chevron right"
              data-direction="next"
              size="large"
              className="zoom-view-next-btn"
              onClick={handleNavigate}
            />
          )}
        </div>
        <h6 className="my-0 px-2 w-100 text-center">{`<<<<<<<< SWIPE TO VIEW MORE >>>>>>`}</h6>

        {activeIndex >= 0 && (
          <div className="zoom-view-thumbnail-container">
            {SHOWCASE_IMAGES.map(item => {
              return (
                <Button
                  style={{ backgroundImage: `url(${item.imgLink.src})` }}
                  className={classnames(['zoom-view-thumbnail'], {
                    active: item.index === activeIndex
                  })}
                  data-index={item.index}
                  key={`item_${item.index}`}
                  id={`thumbnail_${item.index}`}
                  onClick={() => setActiveIndex(item.index)}
                />
              )
            })}
          </div>
        )}
      </Modal>
    </Layout>
  )
}

export default GalleryPage
